<template>
  <div>
    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Departemen"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-12 col-md-6 text-right">
                <b-button
                  @click="btnSubmitChecked"
                  variant="success"
                  class="mx-1"
                >
                  <i class="fas fa-file-import"></i> Posting Satu Sehat
                </b-button>
                <b-button @click="btnSyncAll" variant="success" class="mx-1">
                  <i class="far fa-edit"></i> Update Satu Sehat
                </b-button>
              </div>
            </div>

            <!-- Table -->
            <div>
              <b-table
                striped
                hover
                class="mt-3"
                :items="items"
                :fields="fields"
                :style="'white-space: nowrap'"
                responsive="sm"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '15%' : '' }"
                  />
                </template>
                <template #head(actions)>
                  <b-form-checkbox
                    :id="`id-check-all-departmens`"
                    :name="`name-check-all-departmens`"
                    value="1"
                    unchecked-value="0"
                    @change="btnCheckAll($event)"
                    v-model="formCheckAll"
                  >
                    Check All
                  </b-form-checkbox>
                </template>
                <template #cell(is_satusehat_upload)="data">
                  <b-badge variant="success">{{
                    data.item.is_satusehat_upload == 1 &&
                    data.item.is_need_sync == 0
                      ? "Terkirim"
                      : ""
                  }}</b-badge>

                  <b-badge
                    v-if="
                      data.item.is_need_sync == 1 &&
                      data.item.is_satusehat_upload == 1
                    "
                    variant="warning"
                    >Perlu Singkronisasi</b-badge
                  >

                  <!-- <div v-if="data.item.is_satusehat_upload == 1" class="satu-sehat-wrapper">
                    <img src="/media/satusehat/satusehat.png" alt="" />
                  </div> -->
                </template>
                <template #cell(actions)="data">
                  <!-- <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/masters/departemens/detail/' + data.item.id,
                      })
                    "
                  >
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Ubah"
                    @click="
                      $router.push({
                        path: '/masters/departemens/edit/' + data.item.id,
                      })
                    "
                    v-if="btn"
                  >
                    <i class="fas fa-edit px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger mr-1"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btn"
                  >
                    <i class="fas fa-trash px-0"></i>
                  </b-button> -->
                  <b-button
                    size="sm"
                    class="btn-success btn-satusehat"
                    :class="
                      data.item.is_need_sync == 1 &&
                      data.item.is_satusehat_upload == 1
                        ? 'pr-1'
                        : ''
                    "
                    v-b-tooltip.hover
                    title="Sinkronisasi Satu Sehat"
                    v-if="btn"
                    @click="syncSatuSehat(data.item)"
                  >
                    <i class="fas fa-sync px-0"></i>
                    <sup
                      ><i
                        v-if="
                          data.item.is_need_sync == 1 &&
                          data.item.is_satusehat_upload == 1
                        "
                        class="fas fa-circle text-danger fa-xs px-0"
                      ></i
                    ></sup>
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn-success btn-satusehat ml-1 pr-1"
                    v-b-tooltip.hover
                    v-if="btn && data.item.is_satusehat_upload == 0"
                  >
                    <b-form-checkbox
                      :id="`id-${data.item.id}-departmens`"
                      :name="`name-${data.item.id}-departmens`"
                      value="1"
                      unchecked-value="0"
                      @change="selectData($event, data.item, data.index)"
                      v-model="data.item.is_ss_checked"
                    >
                    </b-form-checkbox>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";
import { getSsOrganizationId } from "@/core/services/jwt.service.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      formCheckAll: 0,
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        {
          key: "is_satusehat_upload",
          label: "Satu Sehat",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      // satusehat
      organizationForm: {
        resourceType: "Organization",
        active: true,
        type: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/organization-type",
                code: "dept",
                display: "Hospital Department",
              },
            ],
          },
        ],
        name: "",
        address: [
          {
            use: "work",
            type: "both",
            line: [""],
            city: "Jakarta",
            country: "ID",
          },
        ],
        partOf: {
          reference: "Organization/",
        },
      },
      organizationEditForm: {
        resourceType: "Organization",
        id: "",
        active: false,
        type: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/organization-type",
                code: "dept",
                display: "Hospital Department",
              },
            ],
          },
        ],
        name: "",
        address: [
          {
            use: "work",
            type: "both",
            line: [""],
            city: "Jakarta",
            country: "ID",
          },
        ],
        partOf: {
          reference: "Organization/",
        },
      },
      dataCollection: [],
    };
  },

  methods: {
    btnCheckAll(evt) {
      if (evt == 1) {
        // Check ALL
        this.dataCollection = [];
        this.items.forEach((element) => {
          if (element.is_satusehat_upload == 0) {
            element.is_ss_checked = evt;
            this.dataCollection.push(element);
          }
        });
      } else if (evt == 0) {
        //  Uncheck ALL
        this.items.forEach((element) => {
          element.is_ss_checked = evt;
        });
        this.dataCollection = [];
      }

    },
    btnSyncAll() {
      this.dataCollection = [];
      this.items.forEach((element) => {
        if (element.is_need_sync == 1) {
          this.updateSatuSehat(element);
        }
      });
    },
    selectData(evt, item, index) {
      if (evt == 1) {
        this.dataCollection.push(item);
      } else if (evt == 0) {
        this.dataCollection.splice(1, 1);
        this.formCheckAll = 0;
      }
    },
    async btnSubmitChecked() {
      SwalLoading.fire();
      this.dataCollection.forEach((element, index) => {
        this.uploadSatuSehat(element, false);

        if (index + 1 == this.dataCollection.length) {
          this.dataCollection = [];
        }
      });
    },
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    filterByName() {
      debounce(() => {
        this.pagination();
      }, 500);
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "departemens",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("departemens/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },
    syncSatuSehat(item) {
      if (item.is_satusehat_upload == 0) {
        this.uploadSatuSehat(item);
      } else {
        this.updateSatuSehat(item);
      }
    },
    async uploadSatuSehat(item) {

      this.organizationForm.name = item.name;
      this.organizationForm.address[0].line[0] =
        "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430";
      this.organizationForm.partOf.reference = `Organization/${getSsOrganizationId()}`;
      let form = {
        data: this.organizationForm,
      };
      // // Make Request
      let response = await moduleBridge.submit(form, "organization");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal",
          text: "Gagal mengirim data ke satu sehat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        let icon;
        if (response.state == "success" && response.success.title == "error") {
          icon = "error";
        } else {
          icon = "success";
        }

        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: icon,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        if (response.success.status == "success") {
          this.updateDepartments(item, response.success.data);
        }

        this.dataCollection = [];
        this.items.forEach((element) => {
          element.is_ss_checked = 0;
        });
        this.formCheckAll = 0;
      }
    },
    async updateSatuSehat(item) {

      this.organizationEditForm.id = item.ss_organization_id;
      this.organizationEditForm.name = item.name;
      this.organizationEditForm.address[0].line[0] =
        "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430";
      this.organizationEditForm.partOf.reference = `Organization/${getSsOrganizationId()}`;
      let form = {
        data: this.organizationEditForm,
        _method: "put",
      };
      // // Make Request
      let response = await moduleBridge.submit(
        form,
        `organization/${item.ss_organization_id}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal",
          text: "Gagal mengirim data ke satu sehat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        let icon;
        if (response.state == "success" && response.success.title == "error") {
          icon = "error";
        } else {
          icon = "success";
        }

        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: icon,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        if (response.success.status == "success") {
          item.is_need_sync = 0;
          this.updateDepartments(item, response.success.data);
        }
      }
    },

    async updateDepartments(item, data) {
      let form = item;
      form.is_satusehat_upload = 1;
      form.ss_organization_id = data.id;
      form["_method"] = "put";
      let response = await module.submitWithoutAlert(
        form,
        `departemens/${item.id}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
      } else {
        // Success
        this.pagination();
      }
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveButton();
  },

  computed: {
    ...mapGetters(["currentSsOrganizationId"]),
  },
};
</script>
